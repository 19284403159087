<template>
  <div class="container">
    <div id="loader">
      <div class="text-center" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </div>

    <div id="content">
      <div class="row mb-2">
        <div class="col-md-12">
          <h4 class="h4" v-if="survey">
            {{ survey.title }}
          </h4>
        </div>
      </div>

      <b-form @submit.prevent="doSubmit()" novalidate>
        <div class="row">

         <!-- Gov Id-->
          <div class="col-md-6 col-sm-12">
            <b-form-group
              class="form-label"
              label="Número de cédula">
              <b-input-group
                id="kgroup"
                class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  min="0"
                  type="number"
                  v-model="form.govId"
                  @keyup.enter.native="doSearchEmployee()"
                  placeholder="Número de cédula"
                  required
                >
                </b-form-input>

                <div class="text-center" v-if="loadingEmployee">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Cargando...</span>
                  </div>
                </div>

                <b-button
                  class="is-primary"
                  type="button"
                  :disabled="!isValidId()"
                  role="button"
                  @click="doSearchEmployee()"
                  variant="primary">
                  Buscar
                </b-button>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Gov Id-->
        </div>

        <hr />

        <div id="survey" class="mb-5" v-if="loadedEmployee">
          <div
            id="questions"
            v-for="(question, idx) in survey.questions"
            :key="question.id"
          >

            <div
              class="row"
              v-if="question.type == 1"
              style="margin-bottom: 20px"
            >
              <div class="col-md-12">
                <label> {{ idx + 1 }}. {{ question.title }} </label>

                <b-form-textarea v-model="form.details[idx]"> </b-form-textarea>
              </div>
            </div>
          </div>

          <b-button
            id="btn-submit"
            class="is-primary"
            type="submit"
            variant="primary"
            :disabled="!isValidForm()"
          >
            Enviar información
          </b-button>
          <br />
          <div class="text-center" v-if="submitting">
            <div class="spinner-border" role="status">
              <span class="sr-only">Enviando datos...</span>
            </div>
          </div>
        </div>
      </b-form>

      <b-modal
        ref="alert-modal"
        :width="640"
        scroll="keep"
        @ok="resetForm()"
        @cancel="resetForm()"
      >
        <div class="container">
          <div class="content">
            <h3 class="is-5 spaced">
              {{ alert.title }}
            </h3>
            <p class="detail">
              {{ alert.detail }}
            </p>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="msg-modal"
        :width="640"
        scroll="keep"
        ok-only
        ok-variant="secondary"
        ok-title="Aceptar"
      >
        <div class="container">
          <div class="content">
            <h3 class="is-5 spaced">
              {{ alert.title }}
            </h3>
            <p class="detail">
              {{ alert.detail }}
            </p>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      slider: {
        range: [
          {
            label: "Pobre",
          },
          {
            label: "Muy buena",
          },
        ],
      },
      survey: null,
      companies: [
        { value: 'knueveint', text: 'K-9 Internacional' },
        { value: 'cops', text: 'Cops K-9' },
        { value: 'abc', text: 'ABC' },
        { value: 'inspectors' , text: 'Klean 9' },
      ],
      loadingEmployee: false,
      loadedClient: false,
      loadedEmployee: false,
      loading: true,
      submitting: false,
      selected: [],
      form: {
        surveyId: 6,
        name: "",
        database: "knueveint",
        details: {},
      },
      alert: {
        title: null,
        detail: null,
      },
    };
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/survey/byId`, {
        params: { id: this.form.surveyId },
      })
      .then((response) => {
        this.survey = response.data;
      })
      .catch((err) => {
        this.alert = {
          title: "Ha ocurrido un error",
          detail: err.response.data,
        };
        this.$refs["msg-modal"].hide();
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    resetForm() {
      this.form = {
        surveyId: 6,
        name: "",
        database: "knueveint",
        details: {},
      };
    },

    isValidForm() {
      for (let idx = 0; idx < this.survey.questions.length; idx++) {
        const detail = this.form.details[idx];
        const question = this.survey.questions[idx];
        if (question.required && !detail) {
          return false;
        }
      }
      return true;
    },

    isValidId() {
      return this.form.govId != "";
    },

    doSearchEmployee() {
      this.loadingEmployee = true;
      axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT}/invoice/user`, {
        params: {
          govId: this.form.govId,
          database: this.form.database,
        }
      }).then(response => {
        if (response) {
          this.handleEmployee(response.data)
        } else {
          this.handleError()
        }
      }).catch(() => {
        this.handleError()
      }).finally(() => {
        this.loadingEmployee = false
      })
    },

    handleEmployee(employee) {
      this.form.userId = employee.id
      this.form.name = employee.name
      this.form.govId = employee.govId
      this.form.code = employee.code
      this.form.departmentId = employee.departmentId
      this.loadedEmployee = true;
    },

    capitalizeName(name) {
      let nameParts = name.split(" ");
      var cleanName = "";
      for (var i = 0; i < nameParts.length; i++) {
        let nm = nameParts[i].toLowerCase();
        nm = nm.charAt(0).toUpperCase() + nm.slice(1);
        if (i != 0) {
          cleanName += " ";
        }
        cleanName += nm;
      }
      return cleanName;
    },

    handleError() {
      this.cleanData();
      this.alert = {
        title: "Empleado no encontrado",
        detail: "El empleado no ha sido encontrado en nuestra base de datos",
      };
      this.$refs["msg-modal"].show();
    },

    cleanData() {
      this.form.details  = [];
    },

    getOptions(options) {
      options.map((f) => {
        return {
          ...f,
          text: options.content,
          value: options.id,
        };
      });
      console.log(options);
    },

    doSubmit() {
      if (!this.isValidForm()) {
        alert("Favor llenar las preguntas faltantes");
        return;
      }
      const details = this.form.details;
      const qobjs = [];
      for (const idx in details) {
        const question = this.survey.questions[idx];
        const detail = details[idx];
        qobjs.push({
          questionId: question.id,
          detail: detail,
        });
      }
      const obj = { ...this.form };
      obj.answers = qobjs;
      this.submitting = true;
      var data = JSON.stringify(obj);
      var config = {
        method: "post",
        url: `${process.env.VUE_APP_SERVER_ENDPOINT}/survey/register`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          console.log(response)
          console.log(this); // <-- problem solved.
          console.log(response);
          //this.resetForm();
          alert(
            "Los datos han sido enviados exitosamente",
            "Gracias por su aporte"
          );
          location.reload();
        })
        .catch(function (err) {
          console.log(this); // <-- problem solved.
          console.log(err);
          if (err.response) {
            this.alert = {
              title: "Atención",
              detail: err.response.data,
            };
            this.loadedClient = false;
          }
        })
        .finally(() => {
          //this.loadedClient = false;
          //this.$refs["alert-modal"].show();
          //this.submitting = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 20px;
  height: 100%;
}
#btn-submit {
  float: right;
}

.custom-button {
  background-color: light-grey;
  width: 100%;
}

.custom-button:active {
  width: 100%;
  background-color: green !important;
  border-color: green !important;
}

.btn-secondary.custom-button {
  border-color: green !important;
  background-color: white !important;
  color: black !important;
}

.btn-secondary.active.custom-button {
  color: white !important;
  background-color: green !important;
  border-color: green !important;
}
</style>
